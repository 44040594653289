import React from "react"
// import Dashboard from "../components/dashboard"
import Seo from "../components/seo"
import LocalDev from '../components/Dashboard/localDev'

const IndexPage = props => {
  return (
    <>
      <Seo title="Dashboard" />
      <LocalDev {...props} />
    </>
  )
}

export default IndexPage
